/**
 * Created by aguerrero on 14/04/2016.
 */
angular
    .module('annexaApp')
    .factory('ThirdFactory',['TableFilter', 'DTColumnBuilder', '$filter', 'Language', '$q', '$http', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'RestService', 'CommonService', 'HelperService', 'AnnexaModalFactory', 'GlobalDataFactory', 'TerritoryFactory', 'DialogsFactory', '$state', 
   	function(TableFilter, DTColumnBuilder, $filter, Language, $q, $http, $rootScope, globalModals, AnnexaFormlyFactory, RestService, CommonService, HelperService, AnnexaModalFactory, GlobalDataFactory, TerritoryFactory, DialogsFactory, $state) {
        var factory = {};

        factory.thirdTypes = [
            {"id": "PHISICAL", "name": "global.thirds.literals.phisical"},
            {"id": "LEGAL", "name": "global.thirds.literals.legal"},
            {"id": "PERSONWITHOUTLEGALPERSONALITY", "name": "global.thirds.literals.personwithoutlegalpersonality"}
        ];
        factory.thirdIdentificationDocumentTypes = [
            {"id": "DNI", "name": "DNI/NIF"},
            {"id": "NIE", "name": "NIE/NIF"},
            {"id": "NIF", "name": "NIF"},
            {"id": "OTHER", "name": (($rootScope.esetMode)?"global.thirds.literals.passport":"global.literals.other")}
        ];
        
        factory.getFilter = function (langCol) {

            var tfilter = [
                { id: 'identificationDocument', type: 'text', order: 0, label: 'global.literals.identifier', preFilter:HelperService.getPrefilter('thirds','tableThirds','identificationDocument'), negated:HelperService.getPrefilterNegated('thirds','tableThirds','identificationDocument')},
                { id: 'name', type: 'text', order: 1, label: 'global.literals.name', preFilter:HelperService.getPrefilter('thirds','tableThirds','name'), negated:HelperService.getPrefilterNegated('thirds','tableThirds','name')},
                { id: 'city', type: 'text', order: 2, label: 'global.literals.city', callAux: true, preFilter:HelperService.getPrefilter('thirds','tableThirds','city'), negated:HelperService.getPrefilterNegated('thirds','tableThirds','city')},
                { id: 'email', type: 'text', order: 3, label: 'global.profile.email', callAux: true, preFilter:HelperService.getPrefilter('thirds','tableThirds','email'), negated:HelperService.getPrefilterNegated('thirds','tableThirds','email')},
                { id: 'phone', type: 'text', order: 4, label: 'global.profile.phone', callAux: true, preFilter:HelperService.getPrefilter('thirds','tableThirds','phone'), negated:HelperService.getPrefilterNegated('thirds','tableThirds','phone')},
                { id: 'identificationReference', type: 'text', order: 5, label: 'global.thirds.literals.identificationReference', preFilter:HelperService.getPrefilter('thirds','tableThirds','identificationReference'), negated:HelperService.getPrefilterNegated('thirds','tableThirds','identificationReference')}
            ];
            return tfilter;

        };

        factory.getListColumns = function($scope) {
            var thirdNameColumn = function(data, type, full, meta){
                var content = "";
                if(full.thirdType == "PHISICAL"){
                    content = '<strong>'+full.name + ((full.surname1Particles)?'&nbsp;'+full.surname1Particles:'') +'&nbsp;'+full.surename1 + ((full.surname2Particles)?'&nbsp;'+full.surname2Particles:'')+ ((full.surename2)?'&nbsp;'+full.surename2:'')+'</strong>';
                }else if(full.corporateName){
                    content = '<strong>'+full.corporateName+'</strong>';
                }
                return content;
            };

            var getHtmlContentRepresentations = function(data, type, full, meta){
                var content = "";
                if(full.representations && full.representations.length > 0){
                    if(full.representations.length == 1) {
                        if (full.representations[0].agent) {
                            if (full.representations[0].agent.completeName){
								content = '<span>' + full.representations[0].agent.completeName + '</span>';
							} else if (full.representations[0].agent.name) {
                                content = '<span>' + full.representations[0].agent.name + ((full.representations[0].agent.surname1Particles)?'&nbsp;'+full.representations[0].agent.surname1Particles:'') + '&nbsp;' + full.representations[0].agent.surename1 + ((full.representations[0].agent.surname2Particles)?'&nbsp;'+full.representations[0].agent.surname2Particles:'') + ((full.representations[0].agent.surename2)?'&nbsp;'+full.representations[0].agent.surename2:'') + '</span>';
                            } else if (full.corporateName) {
                                content = '<span>' + full.representations[0].agent.corporateName + '</span>';
                            }
                        }else{
                            content = '<span translate="global.thirds.literals.datatable.col3"></span>';
                        }

                    }else{
                        content = '<span class="pull-left m-r-xs">' + full.representations.length + '</span> <span class="pull-left-fa" translate="global.thirds.literals.datatable.col3"></span>';
                    }

                }
                return content;
            };

            var getHtmlContentAdresses = function(data, type, full, meta){
                var content = "";
                if(full){
                    var thirdAddress = new ThirdAddress(full, Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
                    content = thirdAddress.getDefaultAddressHtml();
                }
                return content;
            };
            
            var showEnableAction = function (data, type, full, meta) {
                if(full.active){
                    return false;
                } else {
                    return true;
                }
            }

            var showDisableAction = function (data, type, full, meta) {
                if(full.active){
                    return true;
                } else {
                    return false;
                }
            }

            var thirdTypeColumn = new IconFilterColumn($scope, 'filterData', 'thirdType',
                [
                    new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                    new IconSearchOption('PHISICAL','PHISICAL', 'fa-user fa-lg ico-third', 'fa-user fa-fw', 'global.thirds.literals.phisical'),
                    new IconSearchOption('LEGAL','LEGAL', 'fa-bank fa-lg ico-third', 'fa-bank fa-fw', 'global.thirds.literals.legal'),
                    new IconSearchOption('PERSONWITHOUTLEGALPERSONALITY','PERSONWITHOUTLEGALPERSONALITY', 'fa-puzzle-piece fa-lg ico-third', 'fa-puzzle-piece fa-fw', 'global.thirds.literals.personwithoutlegalpersonality')
                ], 'tableDefinition');
            var identificationDocumentColumn = new LinkColumn($filter,'global.thirds.literals.datatable.col1','viewThird','id');

            var thirdActionsColumn = new ActionsColumn(
                $filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see','annexa.thirds.edit({ id: \'[data]\'})','fa-eye', true),
                [new ActionButton('global.literals.remove','removeThird([data])').setPermissions('delete_third'),
                	new ActionButton('global.literals.disable','disableEnableThird([data], false)', undefined, undefined, showDisableAction),
                    new ActionButton('global.literals.enable','disableEnableThird([data], true)', undefined, undefined, showEnableAction)]);
            
            var thirdActiveColumn = new ActiveColumn($scope, 'filterData', 'active',
                    [
                        new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                        new IconSearchOption(1, 1, 'm-l-xs fa fa-circle text-success', 'm-l-xs fa fa-circle text-success', 'global.commonAdmin.filter.statuses.active'),
                        new IconSearchOption(2, 2, 'm-l-xs fa fa-circle text-danger', 'm-l-xs fa fa-circle text-danger', 'global.commonAdmin.filter.statuses.inactive'),
                    ], 'tableDefinition');

            var columns = [
                { id: 'thirdType', column: thirdTypeColumn, sortable: false },
                { id: 'active', column: thirdActiveColumn, sortable: false },
                { id: 'identificationDocument', column: identificationDocumentColumn},
                { id: 'completeName', width: '25%', title:$filter('translate')('global.thirds.literals.datatable.col2'), render: thirdNameColumn},
                { id: 'representations', title:  $filter('translate')('global.thirds.literals.datatable.col3'), render: getHtmlContentRepresentations, sortable: false},
                { id: 'addresses', title: $filter('translate')('global.thirds.literals.datatable.col4'), render: getHtmlContentAdresses, sortable: false },
                { id: 'actions', columnName: 'id', width: '170px', className: 'text-center', title: thirdActionsColumn.getTitle(), render: thirdActionsColumn.getRender, sortable: false}
            ];

            return columns;

        };

        factory.removeThird = function (id) {
            var deferrend = $q.defer();

            $http({
                url: './api/thirds',
                method: 'DELETE',
                params: { id: id }
            }).success(function(data, status) {
                if(status == 500 || status == 406 || status == 403 || status == 404 || status == 409){
                    deferrend.reject(data);
                }else{
                    deferrend.resolve(data);
                }
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        };
        
        factory.disableEnableThird = function (id, enable) {
            var deferrend = $q.defer();

            $http({
                url: './api/thirds/disableEnable',
                method: 'POST',
                params: { id: id , enable: enable}
            }).success(function(data, status) {
                if(status == 500 || status == 406 || status == 403 || status == 404 || status == 409){
                    deferrend.reject(data);
                }else{
                    deferrend.resolve(data);
                }
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        };

        factory.IdentificationReferenceBox = {
            initialize: function(title_box, placeholder_search, advanced_search_title, newButton) {
                this.boxDefinition = new BoxDefinition(title_box, $rootScope.LoggedUser.id);
                if(newButton) {
                    this.boxDefinition.addNew(this.newIdentificationReference, this.completeAddIdentificationReference);
                }
                return this;
            },
            boxDefinition: null,
            newIdentificationReference: function() {
                var modal = angular.copy(globalModals.identificationReferencesAdd);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = { };
                modal.annexaFormly.options = { watchAllExpressions: true };
                modal.extra = this;
                AnnexaFormlyFactory.showModal("modalNewIdentificationReference", modal, this.completedNew, false);
            },
            completeAddIdentificationReference: function () {
                var model = this.annexaFormly.model.modal_body;

                var identificationReference = { identificationReference: model.identificationReference, identificationReferenceType: model.identificationReferenceType };

                this.extra.content.push(identificationReference);
                this.close();

                if(this.extra.saveSearch) {
                    this.extra.saveSearch(identificationReference);
                }
            }
        };

        factory.ConsentBox = {
            initialize: function(title_box, placeholder_search, advanced_search_title, newButton) {
                this.boxDefinition = new BoxDefinition(title_box, $rootScope.LoggedUser.id);
                if(newButton) {
                    this.boxDefinition.addNew(this.newConsent, this.completeAddConsent);
                }
                return this;
            },
            boxDefinition: null,
            newConsent: function() {
                var proccesResult = function (object) {
                    return object.dossierNumber + " (" + object.subject + ")";
                };

                var modal = angular.copy(globalModals.consentsAdd);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = { familyProcedure: {} };
                modal.annexaFormly.options = { watchAllExpressions: true };
                modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.search = function(val) {
                    var filter = {
                        subject: val.val,
                        dossierNumber: val.val
                    };

                    var additional_filter = { };
                    return RestService.filterData('tram', 'Dossier', filter, additional_filter, 0, 10, '', 3)
                        .then(function(data) {
                            var response = [];

                            _.forEach(data.data.content, function (value) {
                                response.push({ id: value.id, object: JSOG.encode(value), value: proccesResult(value) });
                            });

                            response = new SortedArray(response, 'value').sort();

                            return response;
                        });
                };
                
                modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.advancedSearch = function() {
                    var expiredStates = [
                        { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
                        { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
                    ];

                    var tfilterDossier  = new TableFilter();
                    tfilterDossier.addElement('dossierNumber', 'text', 0, 'global.literals.code');
                    tfilterDossier.addElement('family', 'select', 1, 'global.literals.family', 2, 'FamilyProcedure', true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(true);
                    tfilterDossier.addElement('procedure', 'text', 2, 'global.literals.procedure').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('subject', 'text', 3, 'global.literals.subject').setUserChosenFilter(true);
                    tfilterDossier.addElement('thirds', 'text', 4, 'global.literals.thirdsInt').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('thirds_identification', 'text', 5, 'global.literals.thirds_identification').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('dates', 'dateRange', 8, 'global.delegate.startDate').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('creator', 'text', 9, 'global.literals.creator').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('register_entry', 'text', 10, 'global.literals.register_entry_INI').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('expired', 'select', 11, 'global.literals.expiredSate', 3, HelperService.addAllSelect(expiredStates, 'name', Language.getActiveColumn()), true, 'name', true, undefined, Language.getActiveColumn()).setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.loadLocalData([],undefined, undefined, Language.getActiveColumn());

                    var tdDefDossier    = new TableData('tram', 'Dossier', './api/tram/byfilter').setSortName('remainingDays').setSort([[1, 'asc']]).setSkipFirstSearch(true);

                    var DaysColumn          = new RemaingDaysColumn($filter, 'global.literals.days', HelperService);
                    var ProcedureColumn     = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', Language.getActiveColumn());
                    var StDateColumn        = new StartDateColumn($filter, 'global.delegate.startDate');
                    var CreatorUserColumn   = new UserColumn($filter, 'global.literals.creator');
                    var RelatedColumn       = new RelatedDossiersColumn();

                    var tabledataColumnsDossier = [
                        DTColumnBuilder.newColumn(null).renderWith(function(data, type, full, meta){
                            return '<input type="radio" id="'+full.id+'" name ="selected" data-ng-model="radioSelected" data-ng-value="'+ full.id +'" aria-label="{{ \'global.literals.select\' | translate }}">';
                        }).notSortable(),
                        DTColumnBuilder.newColumn('remainingDays').withTitle(DaysColumn.getTitle()).renderWith(DaysColumn.getRender),
                        DTColumnBuilder.newColumn('dossierNumber').withTitle($filter('translate')('global.literals.code')),
                        DTColumnBuilder.newColumn('procedure.' + ProcedureColumn.getColumn()).withTitle(ProcedureColumn.getTitle()),
                        DTColumnBuilder.newColumn('subject').withTitle($filter('translate')('global.literals.dossier'))
                    ];

                    var advancedModalDefinition = new BoxAdvancedSearchModalDefinition('dossierbox', 'global.literals.advancedSearchExpe', tfilterDossier, tdDefDossier, tabledataColumnsDossier, undefined, undefined, modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.advancedSearchAdd, modal).changeSize('modal-lg');
                    advancedModalDefinition.added = [];

                    AnnexaModalFactory.showBoxAdvancedFilter(advancedModalDefinition);
                };
                modal.extra = this;
                AnnexaFormlyFactory.showModal("modalNewConsent", modal, this.completedNew, false);
            },
            completeAddConsent: function () {
                var proccesResult = function (object) {
                    return object.dossierNumber + " (" + object.subject + ")";
                };

                var model = this.annexaFormly.model.modal_body;

                var consent = { consentType: model.consentType.id, title: '', thirdAddress: model.thirdAddress };

                switch(model.consentType.id) {
                    case 'FAMILY_PROCEDURE':
                        consent.familyProcedure = { id: model.familyProcedure.$selected.id };
                        consent.title = model.familyProcedure.$selected.title;
                        break;
                    case 'PROCEDURE':
                        consent.procedure = model.procedure;
                        consent.title = model.procedure[Language.getActiveColumn()];
                        break;
                    case 'DOSSIER':
                        consent.dossier = model.dossier.object;
                        consent.title = proccesResult(consent.dossier);
                        break;
                }

                this.extra.content.push(consent);

                this.close();

                if(this.extra.saveSearch) {
                    this.extra.saveSearch(consent);
                }
            }
        };

        factory.ContactBox = {
            initialize: function(title_box, placeholder_search, advanced_search_title, newButton) {
                this.boxDefinition = new BoxDefinition(title_box, $rootScope.LoggedUser.id);
                if(newButton) {
                    this.boxDefinition.addNew(this.newContact, this.completeAddContact);
                }
                
                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.default_town && $rootScope.app.configuration.default_town.value && !TerritoryFactory.defaultTown){
                    RestService.findOne('StreetCity',$rootScope.app.configuration.default_town.value).then(function(data) {
                        TerritoryFactory.defaultTown = JSOG.decode(data.data);
                    }).catch(function(error) {
                    });
                }
                
                return this;
            },
            boxDefinition: null,
            newContact: function () {
                var addressLanguage = 'address';
                if (!$rootScope.app.configuration.show_long_address_language || !$rootScope.app.configuration.show_long_address_language.value)
                	addressLanguage = 'shortAddress';
                
        		var modal = angular.copy(globalModals.contactsAdd);
                modal.annexaFormly.model = {};
                var showDefaultByTypeMobile = false;
                var defaults;
                defaults = $linq(this.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_MOBILEPHONE\'').toArray();
                if(defaults && defaults.length > 0){
                    showDefaultByTypeMobile = true;
                }
                var showDefaultByTypeEmail = false;
                defaults = $linq(this.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_EMAIL\'').toArray();
                if(defaults && defaults.length > 0){
                    showDefaultByTypeEmail = true;
                }
                var showDefaultByTypePostal = false;
                defaults = $linq(this.content).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                if(defaults && defaults.length > 0){
                    showDefaultByTypePostal = true;
                }
                var showDefaultByTypePostalCode = false;
                defaults = $linq(this.content).where('x => x.defaultByType == true && x.addressType == \'POSTAL_CODE\'').toArray();
                if(defaults && defaults.length > 0){
                	showDefaultByTypePostalCode = true;
                }
                var showDefault = true;
                defaults = $linq(this.content).where('x => x.default == true').toArray();
                if(defaults && defaults.length > 0){
                    showDefault = false;
                }
                var canCreateNewAddress = TerritoryFactory.canCreateAddress();

                modal.annexaFormly.model.modal_body = {addressType:'POSTAL', hasStates:true, hasCities: true, hasStreetTypes: true, showDefaultByTypeMobile:showDefaultByTypeMobile, showDefaultByTypeEmail:showDefaultByTypeEmail, showDefaultByTypePostal:showDefaultByTypePostal, showDefaultByTypePostalCode:showDefaultByTypePostalCode, showDefault:showDefault, territory_:true, canCreateNewAddress:canCreateNewAddress};
                modal.annexaFormly.fields[0].fieldGroup[7].templateOptions.search = function(val) {
                    var filter = {
                		addresslanguage1:  val.val, 
                		addresslanguage2:  val.val, 
                		addresslanguage3:  val.val,
                		addressType:((modal.annexaFormly.model.modal_body.addressTypeTerritory && modal.annexaFormly.model.modal_body.territory_)?{id:modal.annexaFormly.model.modal_body.addressTypeTerritory}:undefined)
                    };
                    
                    var additional_filter = { };
                    return RestService.filterData('territory', 'Address', filter, additional_filter, 0, 10, '', 3).then(function(data) {
                        var response = [];
                        _.forEach(data.data.content, function (value) {
                        	response.push({ id: value.id, object: JSOG.encode(value), value: value[addressLanguage+Language.getActiveColumn()] });
                        });

                        response = new SortedArray(response, 'value').sort();
                        return response;
                    });
                };
                modal.annexaFormly.fields[0].fieldGroup[9].templateOptions.executeFunction = function() {
                	var preCloseFunction = function(address){
                		if(modal.annexaFormly.model.modal_body.territory_){
	                		if(address && address.id){
                				modal.annexaFormly.model.modal_body.address = {id:address.id, value: address[addressLanguage+Language.getActiveColumn()], object:address};
	                    	}
                		}else{
                			if(address){
	                			modal.annexaFormly.model.modal_body.addressThirdAux = address;
                				modal.annexaFormly.model.modal_body.addressThird = address[addressLanguage+Language.getActiveColumn()];
	                    	}
                		}
                	}
                    TerritoryFactory.newAddress(undefined, preCloseFunction, modal.annexaFormly.model.modal_body.territory_);
                };
                var addressForm = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'address'");
                if(addressForm) {
                	addressForm.templateOptions.advancedSearch = function() {
                        var streetTypes = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.streetTypes));
						var countries = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.countries));
						var addressTypes = angular.copy(GlobalDataFactory.addressTypes)
						
						var tfilterDossier  = new TableFilter();
						tfilterDossier.addElement('addressType', 'select', 0, 'global.literals.addressType', 3, HelperService.addAllSelect(addressTypes, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false);
						tfilterDossier.addElement('streetType', 'select', 1, 'global.thirds.literals.streetType', 3, HelperService.addAllSelect(streetTypes, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false);
						tfilterDossier.addElement('street', 'text', 2, 'global.thirds.literals.streetName').setFilterCallAux(true);
						tfilterDossier.addElement('numberAddress', 'text', 3, 'global.thirds.literals.number').setFilterCallAux(true);
						tfilterDossier.addElement('country', 'select', 4, 'global.thirds.literals.country', 3, HelperService.addAllSelect(countries, undefined, Language.getActiveColumn()), true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(false).setUserChosenFilter(true);
						tfilterDossier.addElement('state', 'text', 5, 'global.thirds.literals.state').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('city', 'text', 6, 'global.literals.city').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('postalCode', 'text', 7, 'global.thirds.literals.postalCode').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('pseudoVia', 'text', 8, 'global.thirds.literals.pseudoVia').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('numberAddress2', 'text', 9, 'global.thirds.literals.number2').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('bis', 'text', 10, 'global.thirds.literals.bis').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('bis2', 'text', 11, 'global.thirds.literals.bis2').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('kilometer', 'text', 12, 'global.thirds.literals.kilometer').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('block', 'text', 13, 'global.thirds.literals.block').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('portal', 'text', 14, 'global.thirds.literals.portal').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('stair', 'text', 15, 'global.thirds.literals.stair').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('floor', 'text', 16, 'global.thirds.literals.floor').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('door', 'text', 17, 'global.thirds.literals.door').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('cadastralReference', 'text', 18, 'global.territory.list.cadastral_reference').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('coordinateX', 'text', 19, 'global.territory.list.coordinateX').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('coordinateY', 'text', 20, 'global.territory.list.coordinateY').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.addElement('address', 'text', 21, 'global.territory.list.address').setFilterCallAux(true).setUserChosenFilter(true);
						tfilterDossier.loadLocalData([],undefined, undefined, Language.getActiveColumn());
						
						var tdDefDossier    = new TableData('territory', 'Address', './api/general/filterdata').setSortName('address').setSort([[1, 'asc']]).setSkipFirstSearch(true);
						
						var addressTypeColumn = new DatabaseTranslatedParentColumn($filter, 'global.literals.addressType', Language.getActiveColumn());
						var tabledataColumnsDossier = [
                            DTColumnBuilder.newColumn(null).renderWith(function(data, type, full, meta){
                                return '<input type="radio" id="'+full.id+'" name ="selected" data-ng-model="radioSelected" data-ng-value="'+ full.id +'" aria-label="{{ \'global.literals.select\' | translate }}">';
                            }).notSortable(),
                            DTColumnBuilder.newColumn(addressLanguage+Language.getActiveColumn()).withTitle($filter('translate')('global.territory.list.address')),
                            DTColumnBuilder.newColumn('addressType').withTitle(addressTypeColumn.getTitle()).renderWith(addressTypeColumn.getRender)
                        ];
						
						var advancedModalDefinition = new BoxAdvancedSearchModalDefinition('modalASAddresses', 'global.literals.advanced_search_registerEntry', tfilterDossier, tdDefDossier, tabledataColumnsDossier, undefined, undefined, addressForm.templateOptions.advancedSearchAdd, modal).changeSize('modal-lg');
                        advancedModalDefinition.added = [];
                        advancedModalDefinition.isAdvanced = true;
                        advancedModalDefinition.active = 1;
                        advancedModalDefinition.language = Language.getActiveColumn();
						advancedModalDefinition.addressLanguage = addressLanguage;

                        AnnexaModalFactory.showBoxAdvancedFilter(advancedModalDefinition);
                	}
                }
                modal.extra = this;
                AnnexaFormlyFactory.showModal("modalNewContact", modal, this.completedNew, false);
            },
            completeAddContact: function() {
                var contact = {};

                var contactAux = this.annexaFormly.model.modal_body;
                if(contactAux.territory_){
                	if(contactAux.address && contactAux.address.id){
                        var defaults = $linq(this.extra.content).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                        contact = { addressType:'POSTAL', key: CommonService.guid()};
                        if(contact.addressType == contactAux.addressType){
                            contact.default = contactAux.showDefault;
                        }
                        if(contactAux.showDefaultByTypePostal) {
                            contact.defaultByType = !contactAux.defaultPostal_defaultByType ? false : contactAux.defaultPostal_defaultByType;
                        }else{
                            contact.defaultByType = true;
                        }
                        if(contact.defaultByType && defaults && defaults.length > 0){
                            angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                        }
                    	contact.address = {id: contactAux.address.id, addresslanguage1: contactAux.address.value, addresslanguage2: contactAux.address.value, addresslanguage3: contactAux.address.value, territory:true};
                    	
                        if (!$rootScope.app.configuration.show_long_address_language || !$rootScope.app.configuration.show_long_address_language.value) {
                        	contact.address.shortAddresslanguage1 = contact.address.shortAddresslanguage2 = contact.address.shortAddresslanguage3 = contactAux.address.value;
                        }
                        this.extra.content.push(contact);
                    }
                }else{
                	if(contactAux.addressThirdAux){
                		 var defaults = $linq(this.extra.content).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                         contact = { addressType:'POSTAL', key: CommonService.guid()};
                         if(contact.addressType == contactAux.addressType){
                             contact.default = contactAux.showDefault;
                         }
                         if(contactAux.showDefaultByTypePostal) {
                             contact.defaultByType = !contactAux.defaultPostal_defaultByType ? false : contactAux.defaultPostal_defaultByType;
                         }else{
                             contact.defaultByType = true;
                         }
                         if(contact.defaultByType && defaults && defaults.length > 0){
                             angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                         }
                         contact.address = contactAux.addressThirdAux;
                         if(contact.address){
                        	 contact.address.createThirdAddress = true;
                         }
                         this.extra.content.push(contact);
                	}
                }
                if(contactAux.contactPhone){
                    var defaults = $linq(this.extra.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_MOBILEPHONE\'').toArray();
                    contact = { addressType:'TELEMATIC_MOBILEPHONE', key: CommonService.guid() };
                    if(contact.addressType == contactAux.addressType){
                        contact.default = contactAux.showDefault;
                    }
                    if(contactAux.showDefaultByTypeMobile) {
                        contact.defaultByType = !contactAux.defaultNumber_defaultByType ? false : contactAux.defaultNumber_defaultByType;
                    }else{
                        contact.defaultByType = true;
                    }
                    if(contact.defaultByType && defaults && defaults.length > 0){
                        angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                    }
                    contact.phoneInternationalPrefix = contactAux.contactPrefix;
                    contact.telematicValue = contactAux.contactPhone;
                    this.extra.content.push(contact);
                }
                if(contactAux.contactEmail){
                    var defaults = $linq(this.extra.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_EMAIL\'').toArray();
                    contact = { addressType:'TELEMATIC_EMAIL', key: CommonService.guid() };
                    if(contact.addressType == contactAux.addressType){
                        contact.default = contactAux.showDefault;
                    }
                    if(contactAux.showDefaultByTypeEmail) {
                        contact.defaultByType = !contactAux.defaultEmail_defaultByType ? false : contactAux.defaultEmail_defaultByType;
                    }else{
                        contact.defaultByType = true;
                    }
                    if(contact.defaultByType && defaults && defaults.length > 0){
                        angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                    }
                    contact.telematicValue = contactAux.contactEmail;
                    this.extra.content.push(contact);
                }
                if(contactAux.postalCode){
                    var defaults = $linq(this.extra.content).where('x => x.defaultByType == true && x.addressType == \'POSTAL_CODE\'').toArray();
                    contact = { addressType:'POSTAL_CODE', key: CommonService.guid() };
                    if(contact.addressType == contactAux.addressType){
                        contact.default = contactAux.showDefault;
                    }
                    if(contactAux.showDefaultByTypePostalCode) {
                        contact.defaultByType = !contactAux.defaultPostalCode_defaultByType ? false : contactAux.defaultPostalCode_defaultByType;
                    }else{
                        contact.defaultByType = true;
                    }
                    if(contact.defaultByType && defaults && defaults.length > 0){
                        angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                    }
                    contact.telematicValue = contactAux.postalCode;
                    this.extra.content.push(contact);
                }
                if(contact.default) {
                	contact.default = $linq(GlobalDataFactory.notificationTypes).contains(contact.addressType, "(x, y) => x.addressType.toLowerCase() == y.toLowerCase()");
                }
                contact.observations = contactAux.observations;
                if(this.extra.saveSearch) {
                    this.extra.saveSearch();
                }
                this.close();
            }
        };

        factory.getCitiesByState = function(state){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/thirds/getCitiesByState',
                params: {
                    state: state
                }
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.getStreetsByCity = function(city){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/thirds/getStreetsByCity',
                params: {
                    city: city
                }
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        // TODO: En este método se ha recuperado el campo identificationReference por petición de Marcos para evitar posibles incidencias con algunos clientes 
        // que estan usando integraciones, las cuales todavía no se han adaptado a los nuevos requerimientos, para poder validar tanto el campo como el listado de número de referencia
        factory.existThirdByIdentificationDocumentAndReferences = function (identificationDocument, identificationReference, identificationReferences) {
            var deferrend = $q.defer();

            $http({
                url: './api/thirds/byidentificationReferences',
                method: 'POST',
                params: { identificationDocument: identificationDocument, identificationReference: identificationReference},
                data: identificationReferences
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.existThirdByIdentificationReferenceType = function (identificationReference) {
            var deferrend = $q.defer();

            $http({
                url: './api/thirds/byIdentificationReferenceType',
                method: 'GET',
                params: { identificationReference: identificationReference }
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.newThird = function(third, user, addresses, documents, representations, consents, identificationReferences){
            var decoded = {};
            decoded.third      = third;
            decoded.createUser = user;
            decoded.addresses  = addresses;
            decoded.documents  = documents;
            decoded.thirdRepresentations = representations;
            if(consents) {
                decoded.consents = consents;
            }
            decoded.identificationReferences = identificationReferences;
            var send = JSOG.encode(decoded);

            var deferrend = $q.defer();
            $http({
                url: './api/thirds',
                method: 'POST',
                data: send
            }).then(function(data){
                deferrend.resolve(JSOG.decode(data.data));
            }).catch(function(error){
                deferrend.reject(error.data);
            });

            return deferrend.promise;
        };

        factory.updateThird = function(third) {
            var deferrend = $q.defer();
            $http({
                url: './api/thirds',
                method: 'PUT',
                data: JSOG.encode(third)
            }).then(function (data) {
                deferrend.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferrend.reject(error);
            });
            return deferrend.promise;
        };

        factory.getThird = function(id) {
        	var promises = [];

            promises.push($http({url: './api/thirds', method: 'GET', params: {id:id, origin:'annexa-gee-local'}}));
            promises.push($http({url: './api/thirds/deletedAddress', method: 'GET', params: {id:id}}));
            
            return $q.all(promises).then(function(data) {
                	if(data && data[0].data){
                		factory.Third = JSOG.decode(data[0].data);
                	}
                	if(data && data[1].data){
                		factory.ThirdAddressesDeleted = JSOG.decode(data[1].data);
                	}else{
                		factory.ThirdAddressesDeleted = [];
                	}
                	
            	}).catch(function(error) {
            		factory.Third = {};
            		factory.ThirdAddressesDeleted = [];
                });
        };

        factory.getThirdAddresByThird = function(third, dossier){
            var thirdAddress;
            if(third && third.consents && third.consents.length > 0 && dossier && dossier.id ){
                thirdAddress = $linq(third.consents).where("x => x.consentType == 'DOSSIER' && ((x.dossier && x.dossier.id == "+dossier.id+") || (x.dossierId == "+dossier.id+"))").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                if(!thirdAddress || thirdAddress.length == 0){
                    var idProcedure = (dossier.procedure && dossier.procedure.id)?dossier.procedure.id:-1;
                    thirdAddress = $linq(third.consents).where("x => x.consentType == 'PROCEDURE' && x.procedure.id == "+idProcedure).select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                    if(!thirdAddress || thirdAddress.length == 0){
                        var idFamilyProcedure = (dossier.procedure && dossier.procedure.family && dossier.procedure.family.id)?dossier.procedure.family.id:-1;
                        thirdAddress = $linq(third.consents).where("x => x.consentType == 'FAMILY_PROCEDURE' && x.familyProcedure.id == "+idFamilyProcedure).select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                        if(!thirdAddress || thirdAddress.length == 0){
                            thirdAddress = $linq(third.consents).where("x => x.consentType == 'ALL'").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                            if(!thirdAddress || thirdAddress.length == 0){
                                thirdAddress = $linq(third.addresses).firstOrDefault(undefined, "x => x.default == true");
                            }else{
                                thirdAddress = thirdAddress[0];
                            }
                        }else{
                            thirdAddress = thirdAddress[0];
                        }
                    }else{
                        thirdAddress = thirdAddress[0];
                    }
                }else{
                    thirdAddress = thirdAddress[0];
                }
            }else if(third && third.addresses){
                thirdAddress = $linq(third.addresses).firstOrDefault(undefined, "x => x.default == true");
            }
            return thirdAddress;
        };
        
        factory.getThirdAddresByThirdWithConsent = function(third, dossier, regConsent){
            var thirdAddress;
            if(third && third.consents && third.consents.length > 0 && dossier && dossier.id ){
                thirdAddress = $linq(third.consents).where("x => x.consentType == 'DOSSIER' && ((x.dossier && x.dossier.id == "+dossier.id+") || (x.dossierId == "+dossier.id+"))").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                if(!thirdAddress || thirdAddress.length == 0){
                    var idProcedure = (dossier.procedure && dossier.procedure.id)?dossier.procedure.id:-1;
                    thirdAddress = $linq(third.consents).where("x => x.consentType == 'PROCEDURE' && x.procedure.id == "+idProcedure).select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                    if(!thirdAddress || thirdAddress.length == 0){
                        var idFamilyProcedure = (dossier.procedure && dossier.procedure.family && dossier.procedure.family.id)?dossier.procedure.family.id:-1;
                        thirdAddress = $linq(third.consents).where("x => x.consentType == 'FAMILY_PROCEDURE' && x.familyProcedure.id == "+idFamilyProcedure).select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                        if(!thirdAddress || thirdAddress.length == 0){
                            thirdAddress = $linq(third.consents).where("x => x.consentType == 'ALL'").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                            if(!thirdAddress || thirdAddress.length == 0){
                                thirdAddress = {address:$linq(third.addresses).firstOrDefault(undefined, "x => x.default == true"), isDefault:true};
                            }else{
                                thirdAddress = {address:thirdAddress[0], isDefault:false};
                            }
                        }else{
                            thirdAddress = {address:thirdAddress[0], isDefault:false};
                        }
                    }else{
                        thirdAddress = {address:thirdAddress[0], isDefault:false};
                    }
                }else{
                    thirdAddress = {address:thirdAddress[0], isDefault:false};
                }
            }else if(third && third.consents && third.consents.length > 0 && regConsent){
            	thirdAddress = $linq(third.consents).where("x => x.consentType == 'ALL'").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                if(!thirdAddress || thirdAddress.length == 0){
                    thirdAddress = {address:$linq(third.addresses).firstOrDefault(undefined, "x => x.default == true"), isDefault:true};
                }else{
                    thirdAddress = {address:thirdAddress[0], isDefault:false};
                }
            }else if(third && third.addresses){
                thirdAddress = {address:$linq(third.addresses).firstOrDefault(undefined, "x => x.default == true"), isDefault:true};
            }
            return thirdAddress;
        };

        factory.getThirdAddresWithConsent = function(third, dossier){
            var thirdAddressWithConsent = false;
            var thirdAddress ;
            if(third && third.consents && third.consents.length > 0 && dossier && dossier.id ){
                thirdAddress = $linq(third.consents).where("x => x.consentType == 'DOSSIER' && ((x.dossier && x.dossier.id == "+dossier.id+") || (x.dossierId == "+dossier.id+"))").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                if(!thirdAddress || thirdAddress.length == 0){
                    var idProcedure = (dossier.procedure && dossier.procedure.id)?dossier.procedure.id:-1;
                    thirdAddress = $linq(third.consents).where("x => x.consentType == 'PROCEDURE' && x.procedure.id == "+idProcedure).select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                    if(!thirdAddress || thirdAddress.length == 0){
                        var idFamilyProcedure = (dossier.procedure && dossier.procedure.family && dossier.procedure.family.id)?dossier.procedure.family.id:-1;
                        thirdAddress = $linq(third.consents).where("x => x.consentType == 'FAMILY_PROCEDURE' && x.familyProcedure.id == "+idFamilyProcedure).select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                        if(!thirdAddress || thirdAddress.length == 0){
                            thirdAddress = $linq(third.consents).where("x => x.consentType == 'ALL'").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                            if(!thirdAddress || thirdAddress.length == 0){
                                thirdAddressWithConsent = false;
                            }else{
                                thirdAddressWithConsent = true;
                            }
                        }else{
                            thirdAddressWithConsent = true;
                        }
                    }else{
                        thirdAddressWithConsent = true;
                    }
                }else{
                    thirdAddressWithConsent = true;
                }
            }else if(third && third.addresses){
                thirdAddressWithConsent = false;
            }
            return thirdAddressWithConsent;
        };

        factory.getThirdCompleteName = function (third) {
            return !third.name ?
                third.corporateName :
                third.name + (third.surname1Particles ? ' ' + third.surname1Particles : '') + ' ' + third.surename1 + (third.surname2Particles ? ' ' + third.surname2Particles : '') + (third.surename2 ? ' ' + third.surename2 : '');
        };

        factory.getRelatedThirdType = function (related) {
            if(related.interested) {
                return $filter('translate')('global.literals.interested');
            } else {
                var representated = '';

                if(related.representated && related.representated.identificationDocument) {
                    representated = factory.getThirdCompleteName(related.representated) + ' (' + related.representated.identificationDocument + ')';
                }

                return $filter('translate')('global.literals.representative', { representated: representated });
            }



        };
        
        factory.createRelatedTask = function(thirdId, taskId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/thirds/' + thirdId + '/createRelatedTask/'+taskId
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.deleteRelatedTask = function(thirdId, taskId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/thirds/' + thirdId + '/deleteRelatedTask/'+taskId
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        
        factory.createRepresentation = function(thirdId,representation){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/thirds/'+thirdId+'/createRepresentation',
                data: JSOG.encode(representation)
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.deleteRepresentation = function(thirdId, repId){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/thirds/'+thirdId+'/deleteRepresentation/'+repId
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

		factory.mergeThirds = function (third, table) {
			var modal = angular.copy(globalModals.mergeThirds);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {addThird:((third && third.id)?false:true)};
			if(third && third.id){
				modal.annexaFormly.model.modal_body.third = third;	
			}
            modal.annexaFormly.options = { watchAllExpressions: true };
            var thirdsFromMerge = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'thirdsFromMerge'")
			if(thirdsFromMerge){
				thirdsFromMerge.data.third = ((third && third.id)?third:undefined);
			}
			var completedMerge = function(){
				DialogsFactory.confirm('global.literals.mergeThirds', 'global.literals.mergeThirdsConfirm')
            	.then(function (data) {
					var model = modal.annexaFormly.model.modal_body;
					if(model &&  model.third && model.third.id){
						var thirdsFromMerge = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'thirdsFromMerge'")
						if(thirdsFromMerge && thirdsFromMerge.data && thirdsFromMerge.data.thirds && thirdsFromMerge.data.thirds.length > 0){
							$http({
				                method: 'PUT',
				                url: './api/thirds/mergeThirds/'+model.third.id+'/'+CommonService.getParameterList($linq(thirdsFromMerge.data.thirds).select("x => x.id").toArray())
			            	}).success(function(data, status) {
								modal.close();
								if(table){
									table.reloadInternalData(true, true);
								}else if(third){
									$state.reload();
								}
				            }).error(function(msg,code) {
								modal.alerts = [];
								modal.alerts.push({ msg: $filter('translate')('global.literals.mergeThirdsError') });
				            });
						}else{
							modal.alerts = [];
							modal.alerts.push({ msg: $filter('translate')('global.literals.mergeThirdsErrorFromMerge') });
						}
					}else{
						modal.alerts = [];
						modal.alerts.push({ msg: $filter('translate')('global.literals.mergeThirdsErrorToMerge') });
					}
				}).catch(function (data) {
					modal.close();
	       		});
			}
			AnnexaFormlyFactory.showModal("modalMergeThirds", modal, completedMerge, false);
        };
        
        factory.getPersonByDboid = function(identificationReference){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/thirds/getPersonByDboid',
                params: {
                    dboid: identificationReference
                }
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.deleteThirdConsent = function(consentId){
            var deferrend = $q.defer();
            $http({
                method: 'DELETE',
                url: './api/thirds/deleteThirdConsent/'+consentId
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.addThirdConsent = function(consents){
            var deferrend = $q.defer();
            $http({
                method: 'POST',
                url: './api/thirds/addThirdConsent/',
                data: JSOG.encode(consents)
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        factory.updateThirdConsent = function(consents){
            var deferrend = $q.defer();
            $http({
                method: 'PUT',
                url: './api/thirds/updateThirdConsent/',
                data: JSOG.encode(consents)
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        factory.newThirdDocuments = function(third){
        	var deferrend = $q.defer();
        	$http({
                method: 'PUT',
                url: './api/thirds/thirdDocuments', 
				data: JSOG.encode(third)
            }).success(function(data, status) {
            	 deferrend.resolve(JSOG.decode(data));
			}).error(function(msg,code) {
				deferrend.reject(msg);
            });
        	return deferrend.promise;
        }
        return factory;
    }]);